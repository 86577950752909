/* General styles for the upload container */
.create-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 600px; /* Limit the width for better readability */
  margin: 0 auto; /* Center the container */
  padding: 10px; /* Add padding for spacing */
  border: none; /* Add a border for definition */
  border-radius: 8px; /* Rounded corners */
  background-color: transparent; /* Light background color */
  overflow: hidden; /* Prevent content overflow */
}

/* Style for the upload area */
.upload-area {
  margin-bottom: 20px; /* Space between elements */
}

/* Style for the file label */
.file-label {
  display: block; /* Make label a block element */
  margin-top: 20px;
  padding: 12px 20px; /* Increased padding for better touch targets */
  background-color: #007bff; /* Primary color */
  color: white; /* Text color */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s; /* Smooth transition */
  font-size: 16px; /* Increased font size */
  text-align: center; /* Center text */
}

/* Change background color on hover */
.file-label:hover {
  background-color: #0056b3; /* Darker shade on hover */
  transform: scale(1.05); /* Slightly enlarge on hover */
}

/* Style for the upload button */
.upload-button {
  box-sizing: border-box;
  margin: 10px;
  padding: 12px 20px; /* Increased padding for better touch targets */
  background-color: #28a745; /* Success color */
  color: white; /* Text color */
  border: none; /* Remove border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s; /* Smooth transition */
  font-size: 16px; /* Increased font size */
  text-align: center;
  max-width: 80%;
}

/* Change background color on hover */
.upload-button:hover {
  background-color: #218838; /* Darker shade on hover */
  transform: scale(1.05); /* Slightly enlarge on hover */
}


/* Message styles */
.message {
  margin-top: 10px;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s; /* Smooth transition */
}



/* Extracted text display styles */
.text-output {
  max-width:70%;
  margin: 10px; /* Space above the output container */
  padding: 10px; /* Padding for readability */
  border: 1px solid #ccc; /* Border for definition */
  border-radius: 5px; /* Rounded corners */
  background-color: white; /* Light background for contrast */
  max-height: 400px; /* Limit height for better usability */
  /*overflow-y: auto;  Scrollable for large content */
  /*word-wrap: break-word;  Prevent long words from overflowing */
  font-size: 16px; /* Adjust font size for better readability */
  line-height: 1.5; /* Improve line spacing */
}


.manual-input {
  padding:10px;
  margin: 10px; /* Space between input fields */
  border: 1px solid #ccc; /* Light border */
  border-radius: 5px; /* Rounded corners */
  font-size: 1em; /* Font size for input */
  max-width: 70%;
  
}

.manual-create {
  margin-top:10px;
  padding: 12px 24px; /* Increased padding for better touch targets */
  background-color: #0eac16; /* Success color */
  color: white; /* Text color */
  border: none; /* Remove border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s, transform 0.2s; /* Smooth transition */
  font-size: 16px; /* Increased font size */
  max-width: 80%; /* Full width for better usability */
}

.manual-create :hover {
  background-color: #0b8611; /* Darker shade on hover */
  transform: scale(1.05); /* Slightly enlarge on hover */
}



/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .create-container {
      padding: 15px; /* Adjust padding for smaller screens */
  }

  .file-label {
      padding: 10px; /* Adjust padding for smaller screens */
  }

  .upload-button {
      padding: 10px; /* Adjust padding for smaller screens */
  }

  .text-output {
      max-height: 200px; /* Reduce height for smaller screens */
      font-size: 13px; /* Adjust font size for smaller screens */
  }
}

@media (max-width: 480px) {
  .create-container {
      padding: 10px; /* Further adjust padding for mobile */
  }

  .file-label {
      padding: 8px; /* Further adjust padding for mobile */
  }

  .upload-button {
      padding: 8px; /* Further adjust padding for mobile */
  }

  .text-output {
      max-height: 150px; /* Reduce height for mobile devices */
      font-size: 12px; /* Adjust font size for mobile devices */
  }
}
