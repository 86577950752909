* {
    box-sizing: border-box;
}

html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevent horizontal scrolling */
}



.flashcard-container {
    /*align-items: center;  Center align items vertically */
    /*justify-content: space-between;  Space between items */
    width: 100%; /* Full width */
    /*padding: 10px; /* Padding inside the container */
    margin: 10px auto; /* Margin between flashcards */
    border: 0.5px solid transparent;  /*Light border */
    border-radius: 5px; /* Rounded corners */
    background-color: transparent; /* White background for cards*/ 
    transition: box-shadow 0.3s;  /*Smooth shadow transition */
    
}

.editable-card input, .editable-card textarea {
    align-items: center;
    justify-content: center;
    width: 90%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size:16px;
}

textarea {
    height: 100px;
}



/* Responsive styles */
@media (max-width: 768px) {
    .flashcard-container {
        max-width: 95%;
        flex-direction: row; /* Stack items vertically */
        align-items: stretch; /* Stretch items to full width */
    }
}

@media (max-width: 480px) {
    .flashcard-list {
        padding: 2px; /* Add some padding for smaller screens */
    }

    .flashcard-container {
        max-width: 90%;
        flex-direction: row;
        padding: 2px; /* Reduce padding for smaller screens */
        margin: 5px auto; /* Reduce margin between flashcards */
    }

    .editable-card input, .editable-card textarea, .editable-card select {
        margin: 10px;
        width: 80%; /* Full width for inputs */
        padding: 8px; /* Reduce padding for smaller screens */
        font-size: 14px; /* Adjust font size */
    }

    textarea {
        height: 80px; /* Reduce height for smaller screens */
    }
}