/* FlashCard.css */

.flashcard-container {
    display: flex; /* Use flexbox for layout */
    align-items: center; /* Center items vertically */
    justify-content: center;
    margin: 10px 0; /* Space between flashcard containers */
    overflow: hidden;
}

.flashcard {
    /*flex: 0 1 auto;  /* Allow the card to grow and fill available space */
    width:100%;
    max-width:  180px;
    height: 250px; /* Fixed height for consistency */
    border-radius: 30px; /* Rounded corners */
    transition: transform 0.3s; /* Smooth transition for hover effect */
    background-color: transparent; /* Make background transparent */
}

.flashcard-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s; /* Smooth transition for flipping */
    transform-style: preserve-3d; /* Preserve 3D effect */
    background-color: transparent; /* Ensure no white background during flip */
}

.flashcard.flipped .flashcard-inner {
    transform: rotateY(180deg); /* Flip the card */
}

.flashcard-front, .flashcard-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden; /* Hide the back face when flipped */
    border-radius: 8px; /* Rounded corners */
    display: flex; /* Center content */
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    font-size: 14; /* Decreased font size for better readability */
    color: #040303; /* Dark text color for better contrast */
    padding: 0; /* Reduced padding for text */
    text-align: center; /* Center text horizontally */
}

.flashcard-front {
    background-color: #f496c8; /* background for front */
}

.flashcard-back {
    background-color: #069fd7; /* background for back */
    transform: rotateY(180deg); /* Rotate back face */
}


/* Optional: Add hover effect */
.flashcard:hover {
    cursor: pointer; /* Change cursor on hover */
    transform: scale(1.05); /* Slightly enlarge the card */
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
    .flashcard {
        height: 180px; /* Slightly reduce height */
        max-width: 150px;
    }
    .flashcard-front, .flashcard-back {
        font-size: 0.9em; /* Adjust font size for smaller screens */
    }
}

@media (max-width: 480px) {
    .flashcard {
        height: 160px; /* Further reduce height */
        max-width:120px;
    }
    .flashcard-front, .flashcard-back {
        font-size: 0.8em; /* Adjust font size for mobile */
    }
}