.admin-container {
    padding: 20px;
    max-width: 1000px;
    margin: 0 auto;
}

h1 {
    font-size: clamp(1.8rem, 2.5vw, 2.5rem);
    text-align: center;
}


.user-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
}

.user-card {
    text-align: left;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    background: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.user-card p {
    margin: 8px 0;
}

.approve-button, .email-button {
    margin-top: 10px;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.approve-button {
    background-color: #4caf50;
    color: white;
}

.email-button {
    background-color: #2196f3;
    color: white;
}

@media (max-width: 768px) {
    .user-card {
        font-size: 0.9rem;
    }
}

@media (max-width: 480px) {
    .user-card {
        font-size: 0.8rem;
        padding: 15px;
    }

}
