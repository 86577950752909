/* client/src/components/Dashboard.css */

.dashboard-container {
    text-align: center;
    padding: 20px;
}

.dashboard-blocks {
    display: flex;
    justify-content: space-around; /* Space out the blocks */
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.dashboard-block {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    margin: 10px;
    width: 90%; /* Use percentage for fluid width */
    max-width: 250px; /* Limit max width for larger screens */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s; /* Smooth background transition */
    cursor: pointer; /* Indicate that the block is clickable */
}

.dashboard-block:hover {
    background-color: #f0f0f0bd; /* Light background on hover */
}

.dashboard-block h3 { 
    font-size: 1.2em; /* Responsive font size */
}



/* Media Queries for Responsive Design */
@media (max-width: 768px) {
    .dashboard-block {
        width: 80%; /* Adjust width for tablets */
    }
    .dashboard-block h3 {
        font-size: 1.1em; /* Adjust font size for smaller screens */
    }
}

@media (max-width: 480px) {
    .dashboard-block {
        width: 95%; /* Adjust width for mobile devices */
    }
    .dashboard-block h3 {
        font-size: 1em; /* Adjust font size for mobile */
    }
}