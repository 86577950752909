/* client/src/components/Home.css */

.home-container {
    text-align: center; /* Center the text */
    padding: 10px; /* Increased padding for a more spacious feel */
    background-color: transparent; 
    border-radius: 8px; /* Rounded corners */
    margin: 20px; /* Margin around the container */
}

.home-container h2 {
    font-size: 2.2em; /* Slightly smaller font size for the heading */
    color: white; /* Darker text color */
    margin-bottom: 10px; /* Space below the heading */
}

.home-container p {
    font-size: 1.1em; /* Slightly smaller font size for the paragraph */
    color: #666; /* Lighter text color */
    line-height: 1.4; /* Better line spacing */
    margin-bottom: 20px; /* Space below the paragraph */
}

.home-button {
    display: inline-block; /* Make buttons block elements */
    margin: 30px; /* Reduced space for the buttons */
    padding: 20px 30px; /* Adjusted padding for better touch targets */
    background-color:#04356a; /* Bootstrap primary color */
    color: white; /* White text color */
    text-decoration: none; /* Remove underline */
    border: none;
    border-radius: 5px; /* Rounded corners for the button */
    transition: background-color 0.3s, transform 0.2s;  /*Smooth transition for hover effect */
    font-size: 1.5em; /* Adjusted font size for better visibility */
}

.home-button:hover {
    background-color: #0056b3; /* Darker shade on hover */
    transform: scale(1.08); /* Slightly enlarge the button on hover */
}




/* Media Queries for Responsive Design */
@media (max-width: 768px) {
    .home-container h2 {
        font-size: 2em; /* Adjust font size for tablets */
    }
    .home-container p {
        font-size: 1em; /* Adjust font size for tablets */
    }
    .home-button {
        font-size: 1.3em; /* Adjust button font size for tablets */
        padding: 12px 24px; /* Adjust padding for tablets */
    }
}

@media (max-width: 480px) {
    .home-container h2 {
        font-size: 1.6em; /* Further adjust font size for mobile */
    }
    .home-container p {
        font-size: 0.9em; /* Further adjust font size for mobile */
    }
    .home-button {
        font-size: 1em; /* Adjust button font size for mobile */
        padding: 10px 20px; /* Adjust padding for mobile */
    }
}