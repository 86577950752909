/* Button styles for all */

button.back {
    padding: 10px 10px;
    font-size: 14px;
    color:black;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    background-color:aqua;
    transition:  0.3s ease;
    margin: 10px; /* Space between buttons */ 
}
button:hover {
    transform: scale(1.2); /* Slightly enlarge on hover */
}

button.logout {
    padding: 15px;
    margin: 20px;
    background-color: #04356a; /* Red background for logout */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2em; /* Responsive font size */
}

.dropdown {
    display:flex;
    width:95%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    appearance: none; /* Remove default dropdown arrow */
    outline: none;
    cursor: pointer;
    transition: all 0.3s ease;
    margin:10px;
}
.dropdown:hover {
    transform: scale(1.05); /* Slightly enlarge on hover */
}

@media (max-width:480px) {
    .dropdown {
        max-width: 90%;
    }
}

/* Button styles in the SignUp*/
.signup-container {
    max-width: 400px; /* Maximum width for the form */
    margin: 50px auto; /* Center the form on the page */
    padding: 20px; /* Padding around the form */
    background-color: transparent; /* White background for the form */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    text-align: center; /* Center text */
}

.signup-container h2 {
    margin-bottom: 20px; /* Space below the heading */
    color: white; /* Darker text color */
}

.signup-container input {
    width: 100%; /* Full width for input fields */
    padding: 10px; /* Padding inside input fields */
    margin: 10px 0; /* Space between input fields */
    border: 1px solid #ccc; /* Light border */
    border-radius: 5px; /* Rounded corners */
    font-size: 1em; /* Font size for input */
}

.signup-container button {
    width: 100%; /* Full width for buttons */
    padding: 10px; /* Padding inside buttons */
    margin: 10px 0; /* Space between buttons */
    background-color: #04356a; /* Bootstrap primary color */
    color: white; /* White text color */
    border: none; /* No border */
    border-radius: 5px; /* Rounded corners */
    font-size: 1em; /* Font size for buttons */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.signup-container button:hover {
    background-color: #0056b3; /* Darker shade on hover */
}

/* Media Queries for Responsive Design */
@media (max-width: 480px) {
    .signup-container {
        padding: 15px; /* Adjust padding for mobile */
    }
    .signup-container h2 {
        font-size: 1.8em; /* Adjust heading size for mobile */
    }
    .signup-container input,
    .signup-container button {
        font-size: 0.9em; /* Adjust font size for inputs and buttons */
    }
}




/* Button styles in the FlashCardList */
/* Checkbox styling */
input[type="checkbox"] {
    margin-left: 20px;
    margin-right: 20px; /* Space between checkbox and card */
    transform: scale(1.5); /* Increase size of checkbox */
    cursor: pointer; /* Pointer cursor on hover */
}



.select-all, .delete-all {
    padding: 10px 10px;
    font-size: 14px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    background-color:aqua;
    transition:  0.3s ease;
    margin: 10px; /* Space between buttons */
    margin-left: 15px;
}

button.edit, button.delete-selected, button.save, button.cancel {
    padding: 10px 10px;
    font-size: 14px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    background-color:aqua;
    transition:  0.3s ease;
    margin: 10px; /* Space between buttons */
    margin-left: 15px;
    
}


.dialog {
    flex-direction: column;
    display:flex;
    align-items: center; /* Center align items vertically */
    justify-content: space-between; /* Space between items */
    width: 100%; /* Full width */
    max-width: 600px; /* Limit max width */
    padding: 10px; /* Padding inside the container */
    margin: 10px 0; /* Margin between flashcards */
    border: 0.5px solid whitesmoke;  /*Light border */
    border-radius: 5px; /* Rounded corners */
    background-color: transparent; /* White background for cards */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    transition: box-shadow 0.3s; /* Smooth shadow transition */
}

.folder-list {
    flex-direction:column;
    margin: 10px;
}

.folder-label {
    margin:10px;
    font-size: 14px;
    color: #c0d3e4;
    font-style: italic;
}

.dialog-actions {
    margin: 10px;
}


/*Button styles in the GameMode*/

.custom-amount-container {
    display: flex;
    flex-direction: column; /* Stack label, input, and feedback */
    margin-bottom: 20px; /* Space below the component */
    font-family: Arial, sans-serif; /* Clean, modern font */
    padding: 10px;
    box-sizing: border-box;
    overflow: hidden;
}

.custom-amount-container label {
    font-size: 16px;
    font-weight: bold; /* Make the label stand out */
    color: #f9f7f7; /* Neutral text color */
    margin-bottom: 5px; /* Space between label and input */
}

.custom-amount-container input {
    padding:10px;
    align-items: center;
    width: 100%; /* Full width for responsiveness */
    max-width: 300px; /* Limit the maximum width */
    border: 1px solid #ccc; /* Light border */
    border-radius: 5px; /* Rounded corners */
    font-size: 16px; /* Comfortable font size */
    transition: border-color 0.3s ease; /* Smooth hover effect */
}

.custom-amount-container .total-flashcards {
    color: orange; /* Highlight the total count for clarity */
    font-weight: bold;
}

button.shuffle {
    padding: 10px 10px;
    font-size: 14px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    background-color:aqua;
    transition:  0.3s ease;
    margin: 10px; /* Space between buttons */ 
}

button.start {
    padding: 10px 10px;
    font-size: 14px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    background-color:aqua;
    transition:  0.3s ease;
    margin: 10px; /* Space between buttons */ 
}


/*Button styles for Play*/
button.correct, button.incorrect, button.quit, button.next, button.submit {
    padding: 10px 10px;
    font-size: 14px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    background-color:aqua;
    transition:  0.3s ease;
    margin: 10px; /* Space between buttons */ 
}

.ai-play {
    /*display:flex; /*this line cause chrome can't display the flashcard*/
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.ai-play input, .ai-play textarea {
    justify-content: center;
    width: 90%;
    padding: 10px;
    margin: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size:16px;
}

textarea {
    height: 100px;
}