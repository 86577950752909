/* SignIn.css */

.signin-container {
    max-width: 400px; /* Maximum width for the form */
    margin: 50px auto; /* Center the form on the page */
    padding: 20px; /* Padding around the form */
    background-color: transparent; /* White background for the form */
    border-radius: 8px; /* Rounded corners */
    text-align: center; /* Center text */
}

.signin-container h2 {
    margin-bottom: 20px; /* Space below the heading */
    color: white; /* Darker text color */
}

.signin-container input {
    width:100%;
    max-width: 80%; /* Full width for input fields */
    padding: 10px; /* Padding inside input fields */
    margin: 10px 0; /* Space between input fields */
    border: 1px solid #ccc; /* Light border */
    border-radius: 5px; /* Rounded corners */
    font-size: 1em; /* Font size for input */
}

.signin-container button {
    width: 100%; /* Full width for buttons */
    max-width: 80%;
    padding: 10px; /* Padding inside buttons */
    margin: 10px 0; /* Space between buttons */
    background-color: #04356a; /* Bootstrap primary color */
    color: white; /* White text color */
    border: none; /* No border */
    border-radius: 5px; /* Rounded corners */
    font-size: 1em; /* Font size for buttons */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.signin-container button:hover {
    background-color: #0056b3; /* Darker shade on hover */
}

.error-message {
    color: red; /* Red color for error messages */
    margin-top: 10px; /* Space above the error message */
}

/* Media Queries for Responsive Design */
@media (max-width: 480px) {
    .signin-container {
        padding: 15px; /* Adjust padding for mobile */
    }
    .signin-container h2 {
        font-size: 1.8em; /* Adjust heading size for mobile */
    }
    .signin-container input,
    .signin-container button {
        font-size: 0.9em; /* Adjust font size for inputs and buttons */
    }
}