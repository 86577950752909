/* Overall container styles */
.statistic-container {
    text-align: center;
    font-family: Arial, sans-serif;
    max-width: 90%;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
}
.chart-container {
    padding: 10px;
}

/* Typography */
h2, h3 {
    text-align: center;
}

.carousel-card {
    background-color: rgb(222, 240, 245);
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    text-align: center;
}

.carousel-card:hover {
    transform: scale(1.05);
}

.carousel-card-content h4 {
    font-size: 1.1rem;
    font-weight: bold;
    color: black;
}

.carousel-card-content p {
    font-size: 1rem;
    color: rgb(3, 167, 167);
}

.swiper-container {
    margin-top: 20px;
}

.swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
}



/* Responsive design */
@media (max-width: 768px) {
    .statistic-container {
        padding: 15px;
    }

    .flashcards-info, .card-stats {
        font-size: 1rem;
    }

    .time-range-select {
        width: 100%;
        margin: 10px 0;
    }

    .carousel-card {
        font-size: 0.9rem;  
    }
}

/* Mobile */
@media (max-width: 480px) {
    .statistic-container {
        padding: 10px;
    }

    .flashcards-info, .card-stats {
        font-size: 0.9rem;
    }

    .carousel-card {
        padding: 10px;  
    }
    .image {
        max-width: 50%;
        max-height: 50%;
    }
}


/* Checkin Container styling */

/* Header styling */
.daily-checkin-header {
    font-size: 1.5rem;
    font-weight: bold;
    color: white; /* Neutral text color */
    margin-bottom: 20px;
}

/* Minimalist Calendar Style */
.minimal-calendar {
    border: none;
    background: transparent;
    font-family: 'Arial', sans-serif;
    font-size: 14px;
    padding: 0;
}

/* Remove all the default borders around tiles */
.react-calendar__tile {
    border: none;
    background-color: transparent;
    color: white;
    transition: background-color 0.2s ease, color 0.2s ease;
}

/* Highlight selected date with a clean circle */
.react-calendar__tile--active {
    background-color: none;
    color: white;
}

/* Make the current date stand out subtly */
.react-calendar__tile--now {
    font-weight: bold;
    color: orange;
}

/* Remove the grid lines between the days */
.react-calendar__month-view__days {
    display: grid;
    grid-template-columns: repeat(7, 1fr); /* 7 days in a week */
    gap: 0;
    padding: 10px;
}

/* Remove weekday labels and make them minimal */
.react-calendar__month-view__weekdays {
    display: none;
}

/* Remove the month and year navigation */
.react-calendar__navigation {
    display: none;
}

/* Hover effect for days */
.react-calendar__tile:hover {
    background-color: #f0f0f0;
    cursor: pointer;
    color:black;
}

/* For check-in dates: Highlight with a subtle color */
.check-in-date {
    background: #bcc7f8;
    color: white;
    border-radius: 100%;
}

/* Remove the background and border of the month header */
.react-calendar__month-view__header {
    display: none;
}

/* Optional: Make the calendar more compact */
.react-calendar__month-view__days__day {
    padding: 10px;
    font-size: 16px;
}
